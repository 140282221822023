// --- Imports ---
import axios from 'axios';
import config from './config';
// --- Imports ---


// --- User ---
export function getUserDetails() {
    return axios.get(`${config().api}/api/auth`, {
        withCredentials: true
    });
}

export function clientAvatarURL(client, format = 'png', size = 1024) {
    return `https://cdn.discordapp.com/avatars/${client.id}/${client.avatar}.${format}?size=${size}`
};
// --- User ---


// --- Guilds ---
export function getUserGuilds() {
    return axios.get(`${config().api}/api/discord/guilds`, {
        withCredentials: true
    });
}

export function getGuild(id) {
    return axios.get(`${config().api}/api/discord/guilds/${id}`, {
        withCredentials: true
    });
}

export function getGuildRoles(id) {
    return axios.get(`${config().api}/api/discord/guilds/${id}/roles`, {
        withCredentials: true
    });
}

export function updateGuildPrefix(id, prefix) {
    return axios.put(`${config().api}/api/discord/guilds/${id}/prefix`, {
        prefix
    }, {
        withCredentials: true
    });
}

export function updateGuildRoles(id, roles) {
    return axios.put(`${config().api}/api/discord/guilds/${id}/roles`, {
        roles
    }, {
        withCredentials: true
    });
}

export function guildIconURL(guild, format = 'png', size = 1024) {
    return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.${format}?size=${size}`
};
// --- Guilds ---