export const maxArrayLength = (ar, size, sort, locate) => {

    var arr = [];
    var arry = [];
    var array = [];

    for (let a of ar) {
        arry.push(a);
    }

    if (sort === 'a') {
        arr = arry.sort((a, d) => a[locate] - d[locate]);
    } else if (sort === 'd') {
        arr = arry.sort((a, d) => d[locate] - a[locate]);
    }

    for (var i = 0; i < arr.length; i += size) {
        array.push(arr.slice(i, i + size))
    }

    return array;
};