// --- IMPORTS ---
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Footer } from "../../components";
import { Menu, MenuLoading, MetaTag } from "../../components";
import { BiHomeCircle, BiLogOut, BiPlus, BiUser } from "react-icons/bi";
import config from '../../utils/js/config';
import { useQuery } from '@apollo/client';
import { serverPageQuery } from '../../graphql/queries';
import { getUserDetails } from "../../utils/js/api";
// --- IMPORTS ---


// --- EXPORT PAGE RENDER ---
export function MenuPage() {
  const navigate = useNavigate();
  const refresh = () => window.location.reload();

  React.useEffect(() => {
    getUserDetails().catch(() => {
      navigate('/');
    });
  }, []);
  
  const { loading, error, data } = useQuery(serverPageQuery);
  if (!error) {
    return (
    <div>
      <MetaTag title='Titan Bot | Servers' description={'Your discord servers\n\nA fun multipurpose discord bot with loads of commands! Actions, Music, Utility, and more!\nWith 140+ commands, You can add him to your server, he brings lots of cool stuff'} />
      <div className='wrapper'>
        <div className='box'>
          <h1 className="title">Menu</h1>
          <div className='buttons'>
            <a className='autosize' href="/"><span><BiHomeCircle /></span></a>
          <a href={`https://discord.com/oauth2/authorize?&client_id=${config().client.id}&scope=applications.commands+bot&permissions=66321471&redirect_uri=${config().api}/api/auth/discord/invite/redirect`}><span><BiPlus /> Add Server</span></a>
          </div>
          <div className='buttons'>
            <a  href="/"><span><BiUser /> Profile</span></a>
            <a className='autosize' href={config().api+"/api/auth/discord/logout"}><span><BiLogOut/></span></a>
          </div>
          <div className='xxs-sep'></div>
          <h1 className="title2">Select Server</h1>
          <div className='xxs-sep'></div>
          <div>
            {!loading ? (
              <div>
                <Menu guilds={data.getMutualGuilds} config={config} />
              </div>
            ) : (
              <div>
                <h1 className="subtitle">Loading...</h1>
                <MenuLoading />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
    )
  } else return (
    <div>
      <div className='wrapper'>
        <div className='box'>
          <div className='title2'>There Was An Error</div>
          <div className='xs-sep'></div>
          <div className='title3'>Possible Reasons:</div>
          <div className='subtitle1'>- You are being rate limited</div>
          <div className='subtitle1'>- Discord API is down</div>
          <div className='subtitle1'>- Error 500 : Internal server error</div>
          <div className='xxs-sep'></div>
          <div className='buttons'>
            <button className='rounded' onClick={refresh}><span>Refresh</span></button>
            <a className='autosize rounded' href="/"><span><BiHomeCircle /></span></a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};
// --- EXPORT PAGE RENDER ---