// --- IMPORTS ---
import React from "react";
import { Footer } from "../../components";
import { BiCubeAlt, BiLogIn, BiLogOut } from "react-icons/bi";
import { MdSpaceDashboard } from "react-icons/md";
import { FaDiscord } from "react-icons/fa";
import { getUserDetails } from "../../utils/js/api";
import config from "../../utils/js/config";
// --- IMPORTS ---


// --- EXPORT PAGE RENDER ---
export function LandingPage(props) {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    getUserDetails().then(({data}) => {
      setUser(data);
    }).catch((e) => {});
  }, []);
return (
  <div>
    <div className="content-body">
      <div className="box-wrapper">
        <div className="box">
          <div className="title-details">
            <div className="main-title">Titan-Bot</div>
            <div className="subtitle">Welcome to Titan-Bot Dashboard!</div>
            <p className="subtitle1">
            A fun multipurpose bot with loads of commands! Actions, Music, Utility, and more!<br/>With <code>140+</code> commands, You can add him to your server, he brings loads of fun!
            </p>
          </div>
          <div className="buttons">
            <a href="https://discord.com/oauth2/authorize?&client_id=745849628825747458&scope=applications.commands+bot&permissions=66321471"><span><BiCubeAlt/> Invite</span></a>
            <a href="https://www.dstitan.codes/return/discord/login"><span><FaDiscord/> Server</span></a>
            {user ? <a href="/servers"><span><MdSpaceDashboard/> Dashboard</span></a> : <a href={config().api+"/api/auth/discord/login"}><span><BiLogIn/> Login</span></a>}
            {user ? <a className='autosize' href={config().api+"/api/auth/discord/logout"}><span><BiLogOut/></span></a> : ''}
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
);
};
// --- EXPORT PAGE RENDER ---