// --- IMPORTS ---
import React from "react";
import { FaTwitter, FaDeviantart, FaYoutube, FaTwitch, FaPatreon } from 'react-icons/fa';
// --- IMPORTS ---


// --- EXPORT COMPONENT COMPONENT ---
export function Footer(props) {
return (
<div>
    <footer className="footer">
        <div className="f-container row">
            <div className="footer-col">
                <h4>Titan-Bot</h4>
                <ul>
                    <li><a href="https://titanbot.dstitan.codes">Titan-Bot Home</a></li>
                    <li><a href="/dashboard">Dashboard</a></li>
                    <li><a href="/invite">Invite</a></li>
                    <div className='xxs-sep'></div>
                    <li><a href="https://dstitan.codes">DSTitan Home</a></li>
                </ul>
            </div>
            <div className="footer-col">
                <h4>Product</h4>
                <ul>
                    <li><a href="https://dstitan.codes/commissions">Commissions</a></li>
                    <li><a href="https://dstitan.codes/merch">Merch</a></li>
                    <li><a href="https://dstitan.codes/membership">Membership</a></li>
                </ul>
            </div>
            <div className="footer-col">
                <h4>Help</h4>
                <ul>
                    <li><a href="/faq">FAQ</a></li>
                    <li><a href="https://dstitan.codes/return/discord">Discord</a></li>
                </ul>
            </div>
            <div className="footer-col">
                <h4>Follow</h4>
                <div className="social-links">
                    <a href="https://dstitan.codes/return/twitter"><FaTwitter/></a>
                    <a href="https://dstitan.codes/return/deviantart"><FaDeviantart/></a>
                    <a href="https://dstitan.codes/return/youtube"><FaYoutube/></a>
                    <a href="https://dstitan.codes/return/twitch"><FaTwitch/></a>
                    <a href="https://dstitan.codes/return/patreon"><FaPatreon/></a>
                </div>
            </div>
        </div>
        <div className="tag">Made For You.</div>
        <div className="sep"></div>
        <div className="copyprotect">©<span> 2021 dstitan</span></div>
    </footer>
</div>
);
};
// --- EXPORT COMPONENT COMPONENT ---