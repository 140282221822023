// --- IMPORTS ---
import React from "react";
import {BiHomeCircle, BiMenu, BiCubeAlt, BiLeftArrow, BiMenuAltLeft} from 'react-icons/bi'
// --- IMPORTS ---


// --- EXPORT COMPONENT COMPONENT ---
export function Sidebar(props) {
return (
<div className="nav">
    <div className="sidebar closed">
        <div className="logo-details">
            <BiMenu className='bx-menu'/>
            <BiCubeAlt className="main-icon"/>
            <span className="logo-name">TitanBot</span>
        </div>
        <ul className="nav-links">
            <li>
                <a className="home" href="/">
                    <i><BiHomeCircle className='icon' /></i>
                    <div className="link-name">Home</div>
                </a>
                <ul className="sub-menu blank">
                    <li><a className="link-name" href="/">Home</a></li>
                </ul>
            </li>
            <li>
                <a className="home" href="/servers">
                    <i><BiMenuAltLeft className='icon' /></i>
                    <span className="link-name">Servers</span>
                </a>
                <ul className="sub-menu blank">
                    <li><a className="link-name" href="/servers">Servers</a></li>
                </ul>
            </li>
        </ul>
    </div>
    <div className="home-section">
            <div className="home-content">
                <li>
                    <i><BiLeftArrow className='arrow'/></i>
                </li>
            </div>
        </div>
</div>
);
};
// --- EXPORT COMPONENT COMPONENT ---