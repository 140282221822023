// --- IMPORTS ---
import React from 'react';
import { Helmet } from 'react-helmet';
// --- IMPORTS ---


// --- EXPORT COMPONENT COMPONENT ---
export function MetaTag({title, description}) {
return (
    <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
    </Helmet>
);
};
// --- EXPORT COMPONENT COMPONENT ---