import { gql } from '@apollo/client';

export const updateGuildPrefixMutation = gql`
    mutation UpdateGuildPrefix($id: String!, $prefix: String, $nickname: String) {
        updateGuildPrefix(id: $id, prefix: $prefix, nickname: $nickname) {
            _id
            prefix
            settings {
                bot {
                    nickname
                }
            }
        }
    }
`
export const updateGuildRolesMutation = gql`
    mutation UpdateGuildRoles($id: String!, $muteRole: String, $modRoles: [String]) {
        updateGuildRoles(id: $id, muteRole: $muteRole, modRoles: $modRoles) {
            _id
            settings {
                users {
                    muteRole
                    modRoles
                }
            }
        }
    }
`

export const updateBotIgnoreMutation = gql`
    mutation UpdateBotIgnore($id: String!, $channels: [String], $commands: [String]) {
        updateBotIgnore(id: $id, channels: $channels, commands: $commands) {
            _id
            settings {
                bot {
                    ignore {
                        channels
                        commands
                    }
                }
            }
        }
    }
`

export const updateBotActionImageMutation = gql`
    mutation UpdateBotActionImage($id: String!, $actionImage: Boolean ) {
        updateActionImage(id: $id, actionImage: $actionImage) {
            _id
            settings {
                bot {
                    actionImage
                }
            }
        }
    }
`