import { useEffect, useState } from "react";
import { Formik } from 'formik';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { ListGroup } from 'react-bootstrap';
import { maxArrayLength } from '../../utils/js/utils';
import { BiSave } from 'react-icons/bi';
import { TiWarning } from 'react-icons/ti';
import { Line } from 'react-chartjs-2';

export function Dashboard({
data,
post
}) {

const guild = data.guild;
const roles = data.roles;
const channels = data.channels;
const commands = data.commands;

const muteRoleID = guild.config.settings.users.muteRole ? guild.config.settings.users.muteRole : "";
const modRoleIDs = guild.config.settings.users.modRoles ? guild.config.settings.users.modRoles : "";
const ignoreChannels = guild.config.settings.bot.ignore.channels ? guild.config.settings.bot.ignore.channels : "";
const ignoreCommands = guild.config.settings.bot.ignore.commands ? guild.config.settings.bot.ignore.commands : "";
const changeLogRaw = guild.config.settings.bot.logs;
const changeLogPages = changeLogRaw ? maxArrayLength(changeLogRaw, 10, 'd', 'createdOn') : null;
const stats = guild.stats ? maxArrayLength(guild.stats, 7, 'a', 'date') : null;
const size = stats ? [stats[0].map(g => g.messages), stats[0].map(g => g.joins), stats[0].map(g => g.leaves), stats[0].map(g => g.commands)] : [[]]
const dates = stats ? stats[0].map(g => moment(parseInt(g.date)).format('MMM Do')) : [];

const [changeLog, setChangeLog] = useState([]);
const [actionImage, setActionImage] = useState(null);


useEffect(() => {
setChangeLog(changeLogPages ? changeLogPages[0] : []);
setActionImage(guild.config.settings.bot.actionImage ? 'On' : 'Off');
}, []);


const handleChange = (props, field, value) => {
props.setFieldValue(field, value);
};

const handlePageChange = (data) => {
setChangeLog(changeLogPages[data.selected]);
};

return (
<div className='grid'>

  {/* Stats */}
  <div className='dashboard-card column-2'>
    <h1 className="subtitle">Server Status - Past 7 Days</h1>
    <div>
      <Line data={{
          labels: dates,
          datasets: [
            {
              label: 'Messages Sent',
              data: size[0],
              borderColor: 'rgb(190, 77, 255)',
              tension: 0.3
            },
            {
              label: 'Members Joined',
              data: size[1],
              borderColor: 'rgb(77, 190, 255)',
              tension: 0.3
            },
            {
              label: 'Members left',
              data: size[2],
              borderColor: 'rgb(255, 104, 77)',
              tension: 0.3
            },
            {
              label: 'Commands Ran',
              data: size[3],
              borderColor: 'rgb(255, 20, 77)',
              tension: 0.3
            }
          ]
        }} height={75} options={{
          maintainAspectRatio: true,
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }} />
    </div>
  </div>

  {/* Bot Settings */}
  <div className='dashboard-card'>
    <h1 className="subtitle">Titan-Bot Settings</h1>

    {/* Prefix & Nickname */}
    <Formik initialValues={{prefix: guild.config.prefix, nickname: guild.config.settings.bot.nickname}}
      onSubmit={(values)=>{post.prefix(values)}}>
      {
      (props) => (
      <form className='main-form' onSubmit={props.handleSubmit}>
        <label htmlFor="prefix" className="text-label">Prefix</label>
        <input className='text-input' type='text' name='prefix' onChange={props.handleChange}
          defaultValue={guild.config.prefix} maxLength='3' required />
        <label htmlFor="nickname" className="text-label">Nickname</label>
        <input className='text-input' type='text' name='nickname' onChange={props.handleChange}
          defaultValue={guild.config.settings.bot.nickname} maxLength='32' required />
        <p className='p1'>Update bots display name in the server.</p>
        <div className='buttons'>
          <button className='autosize rounded' type='submit'><span>
              <BiSave />
            </span></button>
        </div>
        <div className='xxxs-sep'></div>
      </form>
      )
      }
    </Formik>

    {/* Mute Role & Mod Roles */}
    <Formik initialValues={{muteRole: muteRoleID, modRoles: modRoleIDs, }} onSubmit={(values)=> {post.roles(values)}}>
      {
      (props) => (
      <form className='main-form' onSubmit={props.handleSubmit}>
        <label htmlFor="muteRole" className="text-label">Muted Role</label><br />
        <BootstrapSelect showTick selectStyle="custom-class" name="modRoles" showSearch className='select-input-boot'
          onChange={e=> handleChange(props, 'muteRole', e.selectedKey[0])} options={
          roles.filter(role => role.name !== '@everyone').map((role) => {
          return {
          "labelKey": role.id,
          "value": role.name,
          "isSelected": muteRoleID === role.id ? true : false,
          "style": {
          "borderRadius": "10px",
          "color": "#FFFFFF",
          "border": "solid 2px rgb(43, 41, 94)",
          "background": "#090813",
          }
          }
          })
          }/>
          <p className='p1'>Role given to users when muted.</p>
          <label htmlFor="modRoles" className="text-label">Mod Roles</label><br />
          <BootstrapSelect showCountOn="4" showTick selectStyle="custom-class" name="modRoles" showSearch
            className='select-input-boot' isMultiSelect onChange={e=> handleChange(props, 'modRoles', e.selectedKey)}
            options={
            roles.filter(role => role.name !== '@everyone').map((role) =>{
            return {
            "labelKey": role.id,
            "value": role.name,
            "isSelected": modRoleIDs.includes(role.id) ? true : false,
            "style": {
            "borderRadius": "10px",
            "color": "#FFFFFF",
            "border": "solid 2px rgb(43, 41, 94)",
            "background": "#090813",
            }
            }
            })
            }/>
            <p className='p1'>Users with these roles will be able to use adimn commands.</p>
            <div className='buttons'>
              <button className='autosize rounded' type='submit'><span>
                  <BiSave />
                </span></button>
            </div>
            <div className='xxxs-sep'></div>
      </form>
      )
      }
    </Formik>

    {/* Ignore channels & commands */}
    <h1 className="subtitle">Blacklist</h1>
    <Formik initialValues={{channels: ignoreChannels, commands: ignoreCommands, }} onSubmit={(values)=>
      {post.blacklist(values)}}>
      {
      (props) => (
      <form className='main-form' onSubmit={props.handleSubmit}>
        <label htmlFor="commands" className="text-label">Commands</label><br />
        <BootstrapSelect showCountOn="4" showTick selectStyle="custom-class" name="commands" showSearch
          className='select-input-boot' isMultiSelect onChange={e=> handleChange(props, 'commands', e.selectedKey)}
          options={
          commands.map((command) =>{
          return {
          "labelKey": command[1],
          "value": `${command[0]} | ${command[1]}`,
          "isSelected": ignoreCommands.includes(command[1]) ? true : false,
          "style": {
          "borderRadius": "10px",
          "color": "#FFFFFF",
          "border": "solid 2px rgb(43, 41, 94)",
          "background": "#090813",
          }
          }
          })
          }/>
          <p className='p1'>The bot will ignore selected commands.</p>

          <label htmlFor="channels" className="text-label">Channels</label><br />
          <BootstrapSelect showCountOn="4" showTick selectStyle="custom-class" name="channels" showSearch
            className='select-input-boot' isMultiSelect onChange={e=> handleChange(props, 'channels', e.selectedKey)}
            options={
            channels.map((channels) =>{
            return {
            "labelKey": channels.id,
            "value": channels.name,
            "isSelected": ignoreChannels.includes(channels.id) ? true : false,
            "style": {
            "borderRadius": "10px",
            "color": "#FFFFFF",
            "border": "solid 2px rgb(43, 41, 94)",
            "background": "#090813",
            }
            }
            })
            }/>
            <p className='p1'>The bot will ignore selected channels.</p>
            <div className='buttons'>
              <button className='autosize rounded' type='submit'><span>
                  <BiSave />
                </span></button>
            </div>
            <div className='xxxs-sep'></div>
      </form>
      )
      }
    </Formik>

    {/* Action Image */}
    <h1 className="subtitle">Action Image</h1>
    <Formik initialValues={{actionImage: guild.config.settings.bot.actionImage ? true : false }} onSubmit={(values)=>
      {post.action(values)}}>
      {
      (props) => (
      <form className='main-form' onSubmit={props.handleSubmit}>
        <p className='p1 text-center'>Enable/Disable the images that are sent when a fun action command is ran.</p>
        <div className='buttons'>
          <button className='autosize rounded' onClick={()=> {

            if (actionImage === 'On') {
            handleChange(props, 'actionImage', false);
            setActionImage('Off');
            }
            if (actionImage === 'Off') {
            handleChange(props, 'actionImage', true);
            setActionImage('On')
            };
            }}><span>{actionImage}</span></button>
        </div>
        <div className='xxxs-sep'></div>
      </form>
      )
      }
    </Formik>
  </div>

  {/* Change log */}
  <div className='dashboard-card'>
    <h1 className="subtitle">Recent Changes</h1>

    {changeLogPages && changeLogPages.length > 0 ? (
    <div>
      <p className='text-center'>({changeLogRaw.length}) changes since

        {moment(parseInt(changeLogPages[changeLogPages.length - 1][changeLogPages[changeLogPages.length - 1].length -
        1].createdOn)).format('MMM Do YYYY | h:mm A')}</p>
      <ReactPaginate pageCount={changeLogPages.length} marginPagesDisplayed={3} onPageChange={handlePageChange}
        containerClassName={'pagination justify-content-center'} pageClassName={'page-item'}
        pageLinkClassName={'page-link'} previousClassName={'page-item'} previousLinkClassName={'page-link'}
        nextClassName={'page-item'} nextLinkClassName={'page-link'} breakClassName={'page-item'}
        breakLinkClassName={'page-link'} activeLinkClassName={'active'} activeClassName={'active'} />
      {changeLog.map((value, i) => (
      <ListGroup className="changelog my-2" key={i}>
        <ListGroup.Item className='item'>
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{value.username}</h5>
            <small>{moment(parseInt(value.createdOn)).format('MMM Do YYYY | h:mm A')}</small>
          </div>
          <p className="mb-1">{value.action}</p>
          <small>ID: {value.id}</small>
        </ListGroup.Item>
      </ListGroup>
      ))}
    </div>
    ) : (
    <div>
      <p className='text-center'>There has been no history of changes for this server</p>
    </div>
    )}
  </div>

  {/* Danger */}
  <OverlayTrigger trigger="hover" placement="top" overlay={<Popover
    style={{ background: '#131129', color: 'white', border: 'solid 2px rgb(43, 41, 94)' }} id="popover-basic">
    <Popover.Header style={{ background: '#131129', color: 'white' }} as="h3">{guild.owner ? 'Be careful' : 'Sorry'}
    </Popover.Header>
    <Popover.Body style={{ background: '#131129', color: 'white' }}>
      {guild.owner ? '' : 'Only the owner of the server can access this.'}
    </Popover.Body>
    </Popover>}>
    <div className='dashboard-card column-2'>
      <div className={guild.owner ? '' : 'lock' }>
        <h1 className="subtitle">
          <TiWarning /> Danger <TiWarning />
        </h1>
        <div className='buttons'>
          <button className='autosize rounded'><span>Reset</span></button>
        </div>
        <p className='p1 text-center'>Please note that reseting or removing the bot will earse all server data.</p>
      </div>
    </div>
  </OverlayTrigger>
</div>
);
}