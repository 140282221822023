// --- REACT IMPORT ---
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import config from './utils/js/config';
// --- REACT IMPORT ---

// --- STYLESHEETS IMPORT ---
import './utils/css/bootstrap.css';
import './utils/css/sweetalert2.min.css';
import './index.css';
import './utils/css/utils.css';
import './utils/css/sidebar.css';
import './utils/css/footer.css';
// --- STYLESHEETS IMPORT ---

// --- APOLLO CLIENT ---
const httpLink = createHttpLink({
  uri: `${config().api}/graphql`,
  credentials: 'include'
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: o => (o._id ? `${o.__typename}:${o._id}`: null),
  })
});
// --- APOLLO CLIENT ---


// --- REACT RENDER ---
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
  );
// --- REACT RENDER ---

reportWebVitals();
