import { gql } from '@apollo/client';

export const serverPageQuery = gql`
    query getServerPageQuery {
        getUser {
            _id
        }
        getMutualGuilds {
            included {
                id
                name
                icon
            }
            excluded {
                id
                name
                icon
            }
        }
    }
`;

export const dashboardPageQuery = gql`
    query getDashboardPageData($id: String!) {
        getUser {
            _id
        }
        getGuild(id: $id) {
            id
            owner
            stats {
                messages
                joins
                leaves
                commands
                date
            }
            details {
                name
            }
            config {
                prefix
                settings {
                    users {
                        muteRole
                        modRoles
                    }
                    bot {
                        nickname
                        actionImage
                        ignore {
                            commands
                            channels
                        }
                        logs {
                            id
                            username
                            action
                            createdOn
                        }
                    }
                }
            }
        }
        getGuildRoles(id: $id) {
            id
            name
        }
        getGuildChannels(id: $id) {
            id
            name
        }
        getCommands
    }
`;