// --- IMPORTS ---
import React from "react";
import { smallToast } from "../../utils/js/toasts";
import { useNavigate, useMatch } from 'react-router-dom';
import { BiHomeCircle } from "react-icons/bi";
import { Footer, Sidebar, Dashboard, MetaTag } from "../../components";
import { useQuery, useMutation } from '@apollo/client';
import { dashboardPageQuery } from "../../graphql/queries";
import { updateGuildPrefixMutation, updateGuildRolesMutation, updateBotIgnoreMutation, updateBotActionImageMutation } from "../../graphql/mutations";
import { getUserDetails } from "../../utils/js/api";
// --- IMPORTS ---


// --- EXPORT PAGE RENDER ---
export function DashboardPage() {
let match = useMatch('/dashboard/:id');
let navigate = useNavigate();
const refresh = () => window.location.reload();

React.useEffect(() => {
  getUserDetails().catch(() => {
    navigate('/');
  });
}, []);

const { loading, error, data } = useQuery(dashboardPageQuery, { variables: {id: match.params.id}});
const [ updatePrefix ] = useMutation(updateGuildPrefixMutation);
const [ updateRoles ] = useMutation(updateGuildRolesMutation);
const [ updateIgnore ] = useMutation(updateBotIgnoreMutation);
const [ updateActionImage ] = useMutation(updateBotActionImageMutation);

const updateGuildPrefix = async ({prefix, nickname}) => {
  try {
    const response = await updatePrefix({variables: { id: match.params.id, prefix, nickname}});
    if (response) smallToast('success', 'Success', 'Changes saved successfully');
  } catch(e) {
    smallToast('error', 'Error', 'Failed to save changes');
    console.log(e);
  }
}

const updateGuildRoles = async ({muteRole, modRoles}) => {
  try {
    const response = await updateRoles({variables: { id: match.params.id, muteRole, modRoles}});
    if (response) smallToast('success', 'Success', 'Changes saved successfully');
  } catch(e) {
    smallToast('error', 'Error', 'Failed to save changes');
    console.log(e);
  }
}

const updateBotIgnore = async ({channels, commands}) => {
  try {
    const response = await updateIgnore({variables: { id: match.params.id, channels, commands}});
    if (response) smallToast('success', 'Success', 'Changes saved successfully');
  } catch(e) {
    smallToast('error', 'Error', 'Failed to save changes');
    console.log(e);
  }
}

const updateBotActionImage = async ({actionImage}) => {
  try {
    const response = await updateActionImage({variables: { id: match.params.id, actionImage}});
    if (response) smallToast('success', 'Success', 'Changes saved successfully');
  } catch(e) {
    smallToast('error', 'Error', 'Failed to save changes');
    console.log(e);
  }
}

if (!error) {
  return (
  <div>
   <Sidebar />
    <div className="content-body">
      <div className="box-wrapper">
        {!loading ? (
          <div>
            <MetaTag title={`${data.getGuild.details.name} | Dashboard`}description={'Dashboard\n\nA fun multipurpose discord bot with loads of commands! Actions, Music, Utility, and more!\nWith 140+ commands, You can add him to your server, he brings lots of cool stuff'} />
            <div className="box">
              <h1 className="title1">{data.getGuild.details.name}</h1>
              <h1 className="title3">Dashboard</h1>
            </div>
              <div className='xxs-sep'></div>
            <div className='box'>
              <Dashboard data={{guild: data.getGuild, user: data.getUser, roles: data.getGuildRoles, channels: data.getGuildChannels, commands: data.getCommands}} post={{prefix: updateGuildPrefix, roles: updateGuildRoles, blacklist: updateBotIgnore, action: updateBotActionImage}} />
            </div>
          </div>
        ) : (
          <div>
            <div className="box">
              <h1 className="title3">Loading...</h1>
            </div>
          </div>
        )}
      </div>
    </div>
    <Footer />
  </div>)
} else return (
  <div>
    <div className='wrapper'>
      <div className='box'>
        <div className='title2'>There Was An Error</div>
        <div className='xs-sep'></div>
        <div className='title3'>Possible Reasons:</div>
        <div className='subtitle1'>- You are being rate limited</div>
        <div className='subtitle1'>- Discord API is down</div>
        <div className='subtitle1'>- Error 500 : Internal server error</div>
        <div className='xxs-sep'></div>
        <div className='buttons'>
          <button className='rounded' onClick={refresh}><span>Refresh</span></button>
          <a className='autosize rounded' href="/"><span><BiHomeCircle /></span></a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
)
};
// --- EXPORT PAGE RENDER ---