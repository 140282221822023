import Swal from 'sweetalert2';

export const smallToast = (icon, title, details, pos, time) => {
    Swal.fire({
        title: title,
        icon: icon ? icon : 'info',
        text: details ? details : null,
        position: pos ? pos : 'top-right',
        timer: time ? time * 1000 : 5000,
        toast: true,
        showConfirmButton: false,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
}