// --- IMPORTS ---
import React from 'react';
import { Link } from 'react-router-dom';
import { guildIconURL } from '../../utils/js/api';
// --- IMPORTS ---


// --- EXPORT PAGE COMPONENT ---
export function Menu({
guilds,
config
}) {

return (
<div>
  <ul className='guild-list'>
    {guilds.included.map((guild, index) => (
    <li key={index}>
      <Link to={"/dashboard/"+guild.id}> <div className='guild'>
      <img className='icon' src={guild.icon ? guildIconURL(guild)
        : 'https://i.pinimg.com/474x/34/91/f3/3491f3e50ab6a4d51a348f9cc2419842.jpg' } alt='' />
      <div className='name'>{guild.name}</div>
</div>
</Link>
</li>
))}
</ul>
<div className='xxs-sep'></div>
{guilds.excluded.length > 0 ? <div className='title2'>Add Bot</div> : ''}
<div className='xxs-sep'></div>

<ul className='guild-list'>
  {guilds.excluded.map((guild, index) => (
  <li key={index}>
    <a href={`https://discord.com/oauth2/authorize?&client_id=${config().client.id}&scope=applications.commands+bot&permissions=66321471&guild_id=${guild.id}&response_type=code&redirect_uri=${config().api}/api/auth/discord/invite/redirect`}>
      <div className='guild'>
      <img className='icon grayscale' src={guild.icon ? guildIconURL(guild)
        : 'https://i.pinimg.com/474x/34/91/f3/3491f3e50ab6a4d51a348f9cc2419842.jpg' } alt='' />
      <div className='name'>{guild.name}</div>
      </div>
    </a>
  </li>
  ))}
</ul>
</div>
);
};
export function MenuLoading({
config
}) {

return (
<div>
  <ul className='guild-list skeleton-events'>
    <li>
      <div className='guild'>
        <div className='icon skeleton d-inline-block'></div>
        <div className='skeleton skeleton-text-center'></div>
      </div>
    </li>
    <li>
      <div className='guild'>
        <div className='icon skeleton d-inline-block'></div>
        <div className='skeleton skeleton-text-center'></div>
      </div>
    </li>
    <li>
      <div className='guild'>
        <div className='icon skeleton d-inline-block'></div>
        <div className='skeleton skeleton-text-center'></div>
      </div>
    </li>
    <li>
      <div className='guild'>
        <div className='icon skeleton d-inline-block'></div>
        <div className='skeleton skeleton-text-center'></div>
      </div>
    </li>
    <li>
      <div className='guild'>
        <div className='icon skeleton d-inline-block'></div>
        <div className='skeleton skeleton-text-center'></div>
      </div>
    </li>
    <li>
      <div className='guild'>
        <div className='icon skeleton d-inline-block'></div>
        <div className='skeleton skeleton-text-center'></div>
      </div>
    </li>

  </ul>
</div>
);
};
// --- EXPORT PAGE COMPONENT ---