// --- IMPORT ---
import { Routes, Route } from 'react-router-dom';
import { DashboardPage, LandingPage, MenuPage } from './pages';
// --- IMPORT ---


// --- App ---
export default function App() {
  return (
    <Routes>
      <Route path='/' element={<LandingPage/>}/>
      <Route path='/servers' element={<MenuPage/>}/>
      <Route path='/dashboard/:id' element={<DashboardPage/>}/>
    </Routes>
  );
}
// --- App ---
